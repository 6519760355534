import './Promo.css';

export default function Promo() {
  return (
    <section className="promo">
      <h1 className="promo__titlte">
        Учебный проект студента факультета Веб-разработки.
      </h1>
    </section>
  );
}
