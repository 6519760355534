const ERROR_MESSAGES = {
  E400: 'Данные не корректны.',
  E401: 'Вы ввели неправильный логин или пароль.',
  E409: 'Пользователь с таким email уже существует.',
  E500_REGISTER: 'При регистрации пользователя произошла ошибка.',
  E500_LOGIN: 'При авторизации произошла ошибка.',
  E500P_ROFILE: 'При обновлении профиля произошла ошибка',
  E500_MOVIES:
    'Во время запроса произошла ошибка. Возможно, проблема с соединением или сервер недоступен. Подождите немного и попробуйте ещё раз',

  EMPTY_REQUEST: 'Введите ключевое слово',
  MOVIES_NOT_FOUND: 'Ничего не нашлось',
};

export default ERROR_MESSAGES;
